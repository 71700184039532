// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["wySXC3Pp8", "eWPmq1e4i", "MK1dlCEL2"];

const serializationHash = "framer-bLgPp"

const variantClassNames = {eWPmq1e4i: "framer-v-1g8ji8z", MK1dlCEL2: "framer-v-1qw6507", wySXC3Pp8: "framer-v-iy0rsu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const humanReadableVariantMap = {Default: "wySXC3Pp8", Muted: "eWPmq1e4i", Primary: "MK1dlCEL2"}

const getProps = ({height, icon, iconWeight, id, width, ...props}) => { return {...props, b0HcS3D0v: icon ?? props.b0HcS3D0v ?? "Check", btgeQFGGA: humanReadableEnumMap[iconWeight] ?? iconWeight ?? props.btgeQFGGA ?? "light", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "wySXC3Pp8"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;iconWeight?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, b0HcS3D0v, btgeQFGGA, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wySXC3Pp8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-iy0rsu", className, classNames)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"wySXC3Pp8"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgba(218, 197, 167, 0.15))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} variants={{eWPmq1e4i: {"--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgb(51, 51, 48))", backgroundColor: "var(--token-cd2934a7-4e35-4347-a32c-9650fca4db23, rgb(242, 242, 242))"}, MK1dlCEL2: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: "var(--token-dca875b7-f855-43c1-bf73-97596e452266, rgb(203, 251, 69))"}}} {...addPropertyOverrides({eWPmq1e4i: {"data-framer-name": "Muted"}, MK1dlCEL2: {"data-framer-name": "Primary"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1yibsr9-container"} layoutDependency={layoutDependency} layoutId={"JSAfmNQFh-container"}><Phosphor color={"var(--token-efbc6c56-80fe-4475-9b37-9684d6e92632, rgb(21, 21, 21))"} height={"100%"} iconSearch={"House"} iconSelection={b0HcS3D0v} id={"JSAfmNQFh"} layoutId={"JSAfmNQFh"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={btgeQFGGA} width={"100%"} {...addPropertyOverrides({MK1dlCEL2: {color: "var(--token-f82457c5-8438-48a1-bcce-dde3448a0d3a, rgb(255, 255, 255))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bLgPp.framer-zy427a, .framer-bLgPp .framer-zy427a { display: block; }", ".framer-bLgPp.framer-iy0rsu { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 32px; height: 42px; justify-content: center; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: 42px; }", ".framer-bLgPp .framer-1yibsr9-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bLgPp.framer-iy0rsu { gap: 0px; } .framer-bLgPp.framer-iy0rsu > * { margin: 0px; margin-bottom: calc(32px / 2); margin-top: calc(32px / 2); } .framer-bLgPp.framer-iy0rsu > :first-child { margin-top: 0px; } .framer-bLgPp.framer-iy0rsu > :last-child { margin-bottom: 0px; } }", ".framer-bLgPp[data-border=\"true\"]::after, .framer-bLgPp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"eWPmq1e4i":{"layout":["fixed","fixed"]},"MK1dlCEL2":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"b0HcS3D0v":"icon","btgeQFGGA":"iconWeight"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLGsFvn1YG: React.ComponentType<Props> = withCSS(Component, css, "framer-bLgPp") as typeof Component;
export default FramerLGsFvn1YG;

FramerLGsFvn1YG.displayName = "Badge (Large)";

FramerLGsFvn1YG.defaultProps = {height: 42, width: 42};

addPropertyControls(FramerLGsFvn1YG, {variant: {options: ["wySXC3Pp8", "eWPmq1e4i", "MK1dlCEL2"], optionTitles: ["Default", "Muted", "Primary"], title: "Variant", type: ControlType.Enum}, b0HcS3D0v: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Check", description: undefined, hidden: undefined, title: "Icon"}, btgeQFGGA: PhosphorControls?.["weight"] && {...PhosphorControls["weight"], defaultValue: "light", description: undefined, hidden: undefined, title: "Icon Weight"}} as any)

addFonts(FramerLGsFvn1YG, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})